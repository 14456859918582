/* eslint-disable simple-import-sort/imports */
import '@/styles/main.scss';

import '@/polyfills/promise.with-resolvers.polyfill';

// Utilities
import { createApp } from 'vue';
import { logger, routerLogMiddleware } from '@/shared/services/logger/logger';
import { logPwaInfos } from '@/shared/utils/pwa';
import { displayRecruitingMessage } from '@/shared/utils/recruitingMessage';

// Middlewares
import {
  authenticated,
  facturationBeta,
  forceSignOut,
  initAnalytics,
  setUserContext,
  updateTitle,
  validateRoute,
  verifyEmail,
  notifyImitationFailure,
} from '@/shared/middlewares';
import { updateIntercomData } from '@/shared/services/intercom/middlewares';
import { analyticsPageViewEvent } from '@/shared/utils/analytics';

// Plugins
import {
  useChunkError,
  useGlobalComponents,
  useHead,
  useKit,
  usePwa,
  usePinia,
  /* useSentry, */
} from '@/shared/plugins';
import { useVuex } from '@/shared/store';
import { useRouter } from '@/shared/router';

// Root
import App from '@/App.vue';

// UnoCss
// eslint-disable-next-line
import 'virtual:uno.css';

const app = createApp(App);
const router = useRouter({ app });
const store = useVuex({ app, router });
useChunkError({ store, router });
usePwa({ router });
useGlobalComponents({ app });
useKit({ app });
useHead({ app });
usePinia({ app });

// Init Middlewares
// Add a one-time beforeEach hook to
// mount the app wait to resolve user & datas
const unregisterHook = router.beforeEach(async (to, from, next) => {
  unregisterHook();
  await setUserContext({ to, next }, { store });
  initAnalytics({ store });
});

router.beforeEach((to, from, next) => validateRoute({ to, next }));
router.beforeEach((to, from, next) => forceSignOut({ to, next }, { store }));
router.beforeEach((to, from, next) => verifyEmail({ to, next }, { store })); //should be before "authenticated", otherwise query params are erased
router.beforeEach((to, from, next) => authenticated({ to, next }, { store }));
router.beforeEach((to, from, next) => updateTitle({ to, next }, { store }));
router.beforeEach((to, from, next) => routerLogMiddleware({ to, from, next }, { store }));
router.beforeEach((to, from, next) => updateIntercomData({ to, next }));
router.beforeEach((to, from, next) => notifyImitationFailure({ to, next }));
router.beforeEach((to, from, next) => facturationBeta({ to, next }, { store }));
router.afterEach((to) => {
  analyticsPageViewEvent({ routeName: to.name, routePath: to.path, store });
});

displayRecruitingMessage();

app.mount('#app');

router.isReady().then(() => {
  // Monitor the use of PWA temporarily
  logPwaInfos({ logger, userId: store?.state?.user?.currentUser?._id });
});
