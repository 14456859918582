import get from 'lodash/get';
import moment from 'moment';

import { getFiscalDeclarationActivity } from '@/modules/closing/microBicFiscalDeclarationActivity/fiscalDeclarationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser les transactions',
      availableBeforeEndYear: true,
      availableOnlyForClients: false,
    },
    livreRecettes: {
      title: 'Livre des recettes',
      availableBeforeEndYear: true,
    },
    checkAssets: {
      title: 'Gérer vos immobilisations',
      availableBeforeEndYear: true,
    },
    lateChequesPreviousYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId) - 1}`,
      availableBeforeEndYear: true,
    },
    lateChequesThisYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId)}`,
      availableOnlyForClients: true,
    },
    checkFinancialBalances: {
      title: 'Vérifier les soldes bancaires',
      availableOnlyForClients: true,
    },
    fiscalExemptions: {
      title: "Exonérations et crédits d'impôts",
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Valider la clôture',
      shortTitle: 'Valider la clôture',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState: state.closingState,
        minDateToClose: moment(activity.date?.minDate),
        isInEarlyClosing: showAdminInfo,
        fiscalPeriodId: String(userCurrentFiscalYear),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getFiscalDeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
