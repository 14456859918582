import georgesApi from '@/shared/apis/georges';

export async function getProductDetails() {
  const { data } = await georgesApi({
    url: '/users/me/product',
    method: 'GET',
  });

  return data;
}

/*
  Compta vers Décla
*/

export async function getInformationToUpgradeToDeclaration() {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/users/me/product/bookkeeping/changeTo/declaration/information',
  });
  return data;
}

/*
  Décla vers Compta
*/

export async function changeUserFromDeclarationToBookkeeping() {
  await georgesApi({
    url: '/users/me/product',
    method: 'POST',
    data: {
      name: 'bookkeeping',
    },
  });
}
