export function shouldShowLastChargeFailedModal({ isLastChargeFailed, isBookkeeping }) {
  return isLastChargeFailed && !isBookkeeping;
}

export function shouldShowChurnedSciScmModal({ hasChurnedInSciOrScm, hasStartedComptePro }) {
  return hasChurnedInSciOrScm && !hasStartedComptePro;
}

export function shouldBypassSubscriptionProblemModal({
  isLastChargeFailed,
  isUserSubscriptionIncomplete,
  hasChurnedInSciOrScm,
  routeName,
  canSeeComptePro,
  userIsAdmin,
  isImpersonating,
  showAdminInfo,
}) {
  // Is user's subscription status problematic?
  const shouldShow = isLastChargeFailed || isUserSubscriptionIncomplete || hasChurnedInSciOrScm;

  // Bypass if the user's subscription is up to date
  if (!shouldShow) {
    return true;
  }

  // Bypass for admins impersonating a user account with admin info active
  const isAdminImpersonatingAndShowingAdminInfo = userIsAdmin && isImpersonating && showAdminInfo; // Admin on user account
  if (isAdminImpersonatingAndShowingAdminInfo) {
    return true;
  }

  // Bypass for admins on their own account
  const isAdminOnTheirOwnAccount = userIsAdmin && !isImpersonating;
  if (isAdminOnTheirOwnAccount) {
    return true;
  }

  // Bypass for ComptePro users on the 'transactions|comptePro' page -> allows them to see their compte pro data
  if (canSeeComptePro && routeName === 'transactions') {
    return true;
  }

  // Bypass for specific routes that should not show the subscription problem modals -> onboarding and payment pages
  const routesWithoutSubscriptionProblemModal = ['userSettingsStripe', 'onboarding'];
  const isUserOnRouteWithoutSubscriptionProblemModal = routesWithoutSubscriptionProblemModal.includes(routeName);
  if (isUserOnRouteWithoutSubscriptionProblemModal) {
    return true;
  }

  return false; // Don't bypass if none of the conditions are met -> paywall the user
}

export function getSubscriptionProblemModalToShow({
  hasStartedComptePro,
  isLastChargeFailed,
  isBookkeeping,
  isUserSubscriptionIncomplete,
  hasChurnedInSciOrScm,
}) {
  if (shouldShowLastChargeFailedModal({ isLastChargeFailed, isBookkeeping })) {
    return 'lastChargeFailModal';
  } else if (isUserSubscriptionIncomplete) {
    return 'lastChargeIncompleteModal';
  } else if (shouldShowChurnedSciScmModal({ hasChurnedInSciOrScm, hasStartedComptePro })) {
    return 'churnedSciScmModal';
  }
}
