<template>
  <GPanelFullscreen v-model="isActive" bg="surface">
    <GPanelFullscreenHeader
      title="Affecter une catégorie"
      :description="description"
      variant="outlined"
      @close="isActive = false"
    />

    <GPanelFullscreenContent>
      <div class="d-flex flex-column pa-32">
        <GCard>
          <GCardContent class="space-y-24">
            <GInputText
              v-model="searchText"
              placeholder="Ex. : repas, essence, mutuelle..."
              type="search"
              name="search"
              prepend-inner-icon="fal fa-search"
              data-appcues-id="transactions.category.search"
              hide-details
              clearable
              autofocus
              @update:model-value="updateSearch"
            />

            <CategoriesList :accounting-account-number="accountingAccountNumber" @change="onChange" />
          </GCardContent>
        </GCard>
      </div>
    </GPanelFullscreenContent>
  </GPanelFullscreen>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';

// Components
import CategoriesList from '@/modules/categories/components/CategoriesList.vue';
import Trackable from '@/shared/mixins/trackable';

export default {
  name: 'CategoriesDesktop',
  emits: ['close', 'change'],
  mixins: [Trackable],

  components: {
    CategoriesList,
  },

  props: {
    opened: Boolean,
    description: String,
    accountingAccountNumber: String,
  },

  data: () => ({
    searchText: '',
  }),

  computed: {
    isActive: {
      get() {
        return this.opened;
      },
      set(value) {
        this.$emit('close', value);
        this.searchText = '';
      },
    },
  },
  beforeUnmount() {
    this.searchText = '';
  },

  methods: {
    ...mapActions({
      highlightCategories: 'categories/highlightCategories',
    }),
    updateSearch(value) {
      if (value && value.length <= 2) return;
      this.highlightCategories({ searchTerm: value });
      this.debouncedSendAnalytics();
    },
    onChange(value) {
      this.$emit('change', value);
      this.searchText = '';
    },
    debouncedSendAnalytics: debounce(function () {
      this.trackEvent({
        action: 'search for transaction category',
        properties: {
          isMobile: false,
          currentCategory: this.searchText,
        },
      });
    }, 2000),
  },
};
</script>
