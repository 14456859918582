import { BOOKKEEPING_PRODUCT_KEY, DECLARATION_PRODUCT_KEY } from '@/modules/user/product/product.constants';

export function formatUserForAppcues({ user }) {
  const result = {
    product: translateProductName({ product: user.product?.[0] }),
    hasActiveSubscription: hasActiveSubscription({ user }),
    planName: user.stripe?.subscription?.plan?.name,
    createdAt: getUnixTimestamp(user.createdAt),
    subscribedAt: getUnixTimestamp(user.stripe?.subscription?.date),
    churnAt: getUnixTimestamp(user.churn?.at),
    firstName: user.profile.firstname,
    lastName: user.profile.name,
    companyName: user.company_name,
    email: user.emails[0].address,
    legalForm: user.currentFiscalYearConfiguration?.legalForm,
    taxRegime: user.currentFiscalYearConfiguration?.taxRegime,
    taxCategory: user.currentFiscalYearConfiguration?.taxCategory,
    currentFiscalYear: user.currentFiscalYearConfiguration?.year,
    fiscalPeriodStartDate: getUnixTimestamp(user.currentFiscalYearConfiguration?.period?.startDate),
    fiscalPeriodEndDate: getUnixTimestamp(user.currentFiscalYearConfiguration?.period?.endDate),
  };
  return result;
}

export function formatUserForDataLayer({ user }) {
  return {
    user_id: user._id,
    accounting_version: translateProductName({ product: user.product?.[0] }),
    accounting_plan: user.stripe?.subscription?.plan?.name,
    accounting_configuration: user.legal_form,
    user_type: hasActiveSubscription({ user }) ? 'client' : 'user',
    user_status: 'logged in',
    user_email: user.emails[0].address,
  };
}

export function getUnixTimestamp(date) {
  if (!date) return;
  return Math.floor(new Date(date).getTime() / 1000);
}

export function translateProductName({ product }) {
  if (product === BOOKKEEPING_PRODUCT_KEY) return 'freemium';
  if (product === DECLARATION_PRODUCT_KEY) return 'premium';
}

export function hasActiveSubscription({ user }) {
  return Boolean(user.stripe?.subscription?.plan?.name) && !user.churn?.at;
}
