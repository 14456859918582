// TODO: Transform this into composable.

import { LEGAL_FORMS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  getTransactionsTabIcon,
  getTransactionsTabName,
  isActivitiesPageVisible,
  isUserOnBookkeepingProduct,
  userCanSeeInvoiceMenu,
  userHasAccessToInvoice,
} from '@/modules/user/product/product.helpers.js';
import { SETTINGS_BACKOFFICE_URL } from '@/shared/constants';
import { resolveStringOrFunction } from '@/shared/utils/string';

export const NAVIGATION_LINKS = [
  {
    path: '/',
    name: 'Pilotage',
    icon: 'fa-chart-bar',
    appCuesId: 'navigation.item.dashboard',
    isActiveRouteConditions: [{ path: '/', options: { isExactPath: true } }],
  },
  {
    path: '/transactions',
    name: ({ user }) => getTransactionsTabName({ user }),
    icon: ({ user }) => getTransactionsTabIcon({ user }),
    appCuesId: 'navigation.item.transactions',
    isActiveRouteConditions: [{ path: '/transactions' }],
    badge: ({ transactionsToCategorizeCount }) => ({
      visible: Boolean(transactionsToCategorizeCount),
      value: transactionsToCategorizeCount,
    }),
  },
  {
    path: '/activities',
    name: 'À faire',
    icon: 'fa-calendar-week',
    appCuesId: 'navigation.item.activites',
    isActiveRouteConditions: [{ path: '/activities' }, { path: '/declaration', options: { isStartWith: true } }],
    badge: ({ activitiesToDoCount }) => ({
      visible: activitiesToDoCount > 0,
      value: activitiesToDoCount,
    }),
    isVisible: isActivitiesPageVisible,
  },
  {
    path: '/facturation',
    name: 'Facturation',
    icon: 'fa-file-invoice',
    appCuesId: 'navigation.item.facturation',
    isActiveRouteConditions: [{ path: '/facturation' }],
    // le bagde est visible si l'utilisateur n'a pas accès à la facturation
    badge: ({ userHasAccessToInvoice }) => ({
      visible: !userHasAccessToInvoice,
      value: 'À venir',
    }),
    // le lien est désactivé si l'utilisateur n'a pas accès à la facturation
    isDisabled: ({ user }) => !userHasAccessToInvoice({ user }),
    // le lien est visible si l'utilisateur a accès à la facturation ou qu'il est bookkeeping
    isVisible: ({ user }) => userCanSeeInvoiceMenu({ user }),
  },
  {
    path: '/documents',
    name: 'Documents',
    icon: 'fa-file-alt',
    appCuesId: 'navigation.item.documents',
    isActiveRouteConditions: [{ path: '/document', options: { isStartWith: true } }],
  },
  {
    href: 'https://wikicompta.indy.fr/fr/',
    name: 'Aide',
    icon: 'fal fa-question-circle',
    isVisible: ({ isMobile, fiscalConfiguration }) => !isMobile && fiscalConfiguration?.legalForm === LEGAL_FORMS.SCI,
  },
  {
    path: '/accompagnement',
    name: 'Accompagnement',
    appCuesId: 'navigation.item.accompagnement',
    icon: 'fal fa-user-headset',
    isActiveRouteConditions: [{ path: '/accompagnement' }],
    isVisible: ({ isMobile, fiscalConfiguration }) => !isMobile && fiscalConfiguration?.legalForm !== LEGAL_FORMS.SCI,
  },
  {
    path: '/votre-compte',
    name: 'Profil',
    icon: 'fa-user',
    isActiveRouteConditions: [
      { path: '/votre-compte' },
      { path: '/compte' },
      { path: '/accompagnement' },
      { path: '/parrainage' },
    ],
    badge: ({ shouldShowNotificationBadge, user }) => ({
      visible: shouldShowNotificationBadge && !isUserOnBookkeepingProduct({ user }),
      dot: true,
    }),
    isVisible: ({ isMobile }) => isMobile,
  },
  {
    href: SETTINGS_BACKOFFICE_URL,
    name: 'Admin',
    icon: 'fal fa-bolt',
    isVisible: ({ isAdmin, isImpersonating }) => isAdmin && !isImpersonating,
  },
];

export function generateNavigationLinks({
  isAdmin = false,
  isMobile = false,
  isImpersonating = false,
  fiscalConfiguration,
  user,
}) {
  return NAVIGATION_LINKS.map((link) => ({
    ...link,
    name: resolveStringOrFunction(link.name, { user }),
    icon: resolveStringOrFunction(link.icon, { user }),
    disabled: link.isDisabled?.({ user }) ?? link.disabled,
  })).filter((link) => link.isVisible?.({ user, isMobile, isAdmin, isImpersonating, fiscalConfiguration }) ?? true);
}

export function getNavigationBadges({
  transactionsToCategorizeCount,
  activitiesToDoCount,
  shouldShowNotificationBadge,
  user,
}) {
  return {
    transactionsToCategorizeCount,
    activitiesToDoCount,
    userHasAccessToInvoice: userHasAccessToInvoice({ user }),
    shouldShowNotificationBadge,
    user,
  };
}

export function getDiscountMessage({ userCoupons, userCouponButtonTooltipText, daysLeftBeforeTrialEnds }) {
  if (!isEmpty(userCoupons)) return userCouponButtonTooltipText;
  return `Votre essai gratuit expire dans ${daysLeftBeforeTrialEnds} jours.`;
}

export function getNavigationMenuText({ userFirstname, isImpersonating }) {
  const isFirstnameTooLong = userFirstname.length > 12;
  if (isFirstnameTooLong) return userFirstname;
  if (isImpersonating) return `Vous imitez ${userFirstname}`;
  return `Bonjour ${userFirstname}`;
}

export function hasSettingError({ user, subscriptionIncomplete }) {
  const lastChargeFailed = get(user, 'stripe.lastChargeFailed', false);
  if (lastChargeFailed || subscriptionIncomplete) return true;
  return false;
}
