import get from 'lodash/get';

import {
  getStripeCustomerData,
  getStripeCustomerInvoiceItems,
  getSubscriptionData,
} from '@/modules/settings/services/stripe.services.js';
import { computeSumPendingCreditInvoiceItems } from '@/modules/settings/settings.store.helpers.js';
import { getProductDetails } from '@/modules/user/product/product.services.js';

import { getCaDeclarationSettingsOptions } from './modules/Options/options.service';

export const stateData = {
  stripeCustomerData: undefined,
  sumPendingCreditInvoiceItems: 0,
  subscriptionPlan: undefined,
  subscriptionTaxRate: undefined,
  featuresSubscriptionModalsList: undefined,
  canSwitchTaxRegime: false,
  needToSwitchPlanForTaxRegimeSwitch: false,
  canUpgradeToDeclaration: false,
  caDeclarationOptions: { fetching: true },
};

export const mutations = {
  updateProductDetails(state, { taxRegimeSwitch, canChangeTo }) {
    state.canSwitchTaxRegime = get(taxRegimeSwitch, 'available', false);
    state.needToSwitchPlanForTaxRegimeSwitch = get(taxRegimeSwitch, 'needToSwitchPlan', false);
    state.canUpgradeToDeclaration = get(canChangeTo, 'declaration.value', false);
  },
  updateCustomerData(state, customerData) {
    state.stripeCustomerData = customerData;
  },
  updateSumPendingCreditInvoiceItems(state, sum) {
    state.sumPendingCreditInvoiceItems = sum;
  },
  updateSubscriptionData(state, { subscriptionTaxRate, subscriptionPlan }) {
    state.subscriptionPlan = subscriptionPlan;
    state.subscriptionTaxRate = subscriptionTaxRate;
    state.featuresSubscriptionModalsList = subscriptionPlan?.featuresSubscriptionModalsList ?? [];
  },
  updateCaDeclarationOptions(state, caDeclarationOptions) {
    state.caDeclarationOptions = caDeclarationOptions;
  },

  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const getters = {
  isUserSubscriptionIncomplete: (state) =>
    get(state.stripeCustomerData, 'subscriptions.data[0].status') === 'incomplete',
  stripeCustomerData: (state) => state.stripeCustomerData,
  sumPendingCreditInvoiceItems: (state) => state.sumPendingCreditInvoiceItems,
  subscriptionPlan: (state) => state.subscriptionPlan,
  subscriptionTaxRate: (state) => state.subscriptionTaxRate,
  featuresSubscriptionModalsList: (state) => state.featuresSubscriptionModalsList,
  canSwitchTaxRegime: (state) => state.canSwitchTaxRegime,
  needToSwitchPlanForTaxRegimeSwitch: (state) => state.needToSwitchPlanForTaxRegimeSwitch,
  canUpgradeToDeclaration: (state) => state.canUpgradeToDeclaration,
  paymentMethod: () => 'card', // todo
  caDeclarationOptions: (state) => state.caDeclarationOptions,
};

export const actions = {
  async getProductDetails({ commit }) {
    const productDetails = await getProductDetails();
    commit('updateProductDetails', productDetails);
  },
  async getStripeCustomerData({ commit }) {
    const customerData = await getStripeCustomerData();
    commit('updateCustomerData', customerData);
  },
  async getStripeCustomerInvoiceItems({ commit }) {
    const invoiceItems = await getStripeCustomerInvoiceItems();
    commit('updateSumPendingCreditInvoiceItems', computeSumPendingCreditInvoiceItems(invoiceItems));
  },
  async getSubscriptionData({ commit }) {
    const { subscriptionTaxRate, subscriptionPlan } = await getSubscriptionData();
    commit('updateSubscriptionData', { subscriptionTaxRate, subscriptionPlan });
  },
  async fetchCaDeclarationSettingsOptions({ commit }) {
    try {
      const options = await getCaDeclarationSettingsOptions();

      commit('updateCaDeclarationOptions', options);
    } catch {
      commit('updateCaDeclarationOptions', { isAllowed: false });
    }
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  actions,
  getters,
};
