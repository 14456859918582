import { defineAsyncComponent, markRaw } from 'vue';

export const DOCUMENTS = {
  AssetsRegisterDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/assetRegister/AssetRegisterDocumentBox.vue')),
  ),
  BalanceDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/Balance/BalanceDocumentBox.vue')),
  ),
  BilanDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/Bilan/BilanDocumentBox.vue')),
  ),
  BilanSciDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/Bilan/BilanSciDocumentBox.vue')),
  ),
  BookkeepingDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/BookkeepingEntries/BookkeepingEntriesDocumentBox.vue'),
    ),
  ),
  CfeDocumentBox: markRaw(defineAsyncComponent(() => import('@/modules/documents/components/Cfe/CfeDocumentBox.vue'))),
  CompteDeResultatDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/CompteDeResultat/CompteDeResultatDocumentBox.vue'),
    ),
  ),
  CompteDeResultatSciDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/CompteDeResultat/CompteDeResultatSciDocumentBox.vue'),
    ),
  ),
  D2035DocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2035/D2035DocumentBox.vue')),
  ),
  D2036DocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2036/D2036DocumentBox.vue')),
  ),
  D2042CProDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2042CPro/D2042CProDocumentBox.vue')),
  ),
  D2572DocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2572/D2572DocumentBox.vue')),
  ),
  D2572SciDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2572/D2572SciDocumentBox.vue')),
  ),
  D2571DocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/D2571/D2571DocumentBox.vue')),
  ),
  DAS2DocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/DAS2/DAS2DocumentBox.vue')),
  ),
  FecDocumentBox: markRaw(defineAsyncComponent(() => import('@/modules/documents/components/Fec/FecDocumentBox.vue'))),
  GrandLivreDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/accountingBook/GrandLivreDocumentBox.vue')),
  ),
  LiasseFiscaleIsDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/LiasseFiscaleIs/LiasseFiscaleIsDocumentBox.vue')),
  ),
  LiasseFiscaleSciDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/LiasseFiscaleSci/LiasseFiscaleSciDocumentBox.vue'),
    ),
  ),
  OgbncDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/Ogbnc/OgbncDocumentBox.vue')),
  ),
  PayslipDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/Payslip/PayslipDocumentBox/PayslipDocumentBox.vue'),
    ),
  ),
  PvOfAgDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/PvOfAg/PvOfAgDocumentBox.vue')),
  ),
  ProcesVerbalAssembleeGeneraleDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import(
        '@/modules/documents/components/ProcesVerbalAssembleeGenerale/ProcesVerbalAssembleeGeneraleDocumentBox.vue'
      ),
    ),
  ),
  ReceiptsDocumentBox: markRaw(
    defineAsyncComponent(() => import('@/modules/documents/components/Receipts/ReceiptsDocumentBox.vue')),
  ),
  SocialDeclarationDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/SocialDeclaration/SocialDeclarationDocumentBox.vue'),
    ),
  ),
  VatDocumentBox: markRaw(defineAsyncComponent(() => import('@/modules/documents/components/Vat/VatDocumentBox.vue'))),
  CompteProAccountStatementsDocumentBox: markRaw(
    defineAsyncComponent(() =>
      import('@/modules/documents/components/CompteProAccountStatements/CompteProAccountStatementsDocumentBox.vue'),
    ),
  ),
};

export const DOCUMENTS_CONFIG = [
  { key: 'bilan', component: 'BilanDocumentBox' },
  { key: 'd2036', component: 'D2036DocumentBox' },
  { key: 'd2035', component: 'D2035DocumentBox' },
  { key: 'vat', component: 'VatDocumentBox' },
  { key: 'compteDeResultat', component: 'CompteDeResultatDocumentBox' },
  { key: 'd2042', component: 'D2042CProDocumentBox' },
  { key: 'socialDeclaration', component: 'SocialDeclarationDocumentBox' },
  { key: 'd2033', component: 'LiasseFiscaleIsDocumentBox' },
  { key: 'pvOfAg', component: 'PvOfAgDocumentBox' },
  { key: 'das2', component: 'DAS2DocumentBox' },
  { key: 'd2571', component: 'D2571DocumentBox' },
  { key: 'd2572', component: 'D2572DocumentBox' },
  { key: 'payslip', component: 'PayslipDocumentBox' },
  { key: 'accountingBook', component: 'GrandLivreDocumentBox' },
  { key: 'generalBalance', component: 'BalanceDocumentBox' },
  { key: 'assetRegister', component: 'AssetsRegisterDocumentBox' },
  { key: 'ogbnc', component: 'OgbncDocumentBox' },
  { key: 'fec', component: 'FecDocumentBox' },
  { key: 'receipts', component: 'ReceiptsDocumentBox' },
  { key: 'cfe', component: 'CfeDocumentBox' },
  { key: 'd2072', component: 'LiasseFiscaleSciDocumentBox' },
  { key: 'd2033Sci', component: 'LiasseFiscaleSciDocumentBox' },
  { key: 'bilanSci', component: 'BilanSciDocumentBox' },
  { key: 'compteDeResultatSci', component: 'CompteDeResultatSciDocumentBox' },
  { key: 'd2572Sci', component: 'D2572SciDocumentBox' },
  { key: 'procesVerbalAssembleeGeneraleSci', component: 'ProcesVerbalAssembleeGeneraleDocumentBox' },
  { key: 'compteProAccountStatements', component: 'CompteProAccountStatementsDocumentBox' },
  { key: 'bookkeeping', component: 'BookkeepingDocumentBox' },
];

export const DOCUMENT_GROUPS = [
  {
    key: 'comptabilite',
    name: 'Comptabilité',
    icon: 'fal fa-folders',
    color: 'success',
    documents: [
      { key: 'bilan' },
      { key: 'bilanSci' },
      { key: 'compteDeResultat' },
      { key: 'accountingBook' },
      { key: 'generalBalance' },
      { key: 'fec' },
      { key: 'assetRegister' },
      { key: 'compteDeResultatSci' },
      { key: 'bookkeeping' },
    ],
    shouldShowPeriodPicker: true,
  },
  {
    key: 'declarations-fiscale',
    name: 'Déclarations fiscales',
    icon: 'fal fa-user',
    color: 'warning',
    documents: [
      { key: 'd2033' },
      { key: 'd2035' },
      { key: 'd2036' },
      { key: 'd2065' },
      { key: 'd2069Sci' },
      { key: 'd2033Sci' },
      { key: 'd2072' },
      { key: 'd2571' },
      { key: 'd2072Sci' },
      { key: 'd2042' },
      { key: 'd2044' },
      { key: 'socialDeclaration' },
      { key: 'das2' },
      { key: 'vat' },
      { key: 'cfe' },
      { key: 'd2572' },
      { key: 'd2572Sci' },
      { key: 'ogbnc' },
    ],
    shouldShowPeriodPicker: true,
  },
  {
    key: 'pv-ag',
    name: `PV d'AG`,
    icon: 'fal fa-file',
    color: 'error',
    documents: [{ key: 'pvOfAg' }, { key: 'procesVerbalAssembleeGeneraleSci' }],
    shouldShowPeriodPicker: true,
  },
  {
    key: 'paie',
    name: `Paie`,
    icon: 'fal fa-money-check-edit',
    color: 'error',
    documents: [{ key: 'payslip' }],
    shouldShowPeriodPicker: true,
  },
  {
    key: 'justificatifs',
    name: `Justificatifs`,
    icon: 'fal fa-receipt',
    color: 'info',
    documents: [{ key: 'receipts' }],
    shouldShowPeriodPicker: false,
  },
  {
    key: 'compte-pro',
    name: `Compte pro`,
    icon: 'fal fa-university',
    color: 'primary',
    documents: [{ key: 'compteProAccountStatements' }],
    shouldShowPeriodPicker: false,
  },
];
