import { DECLARATIONS } from '@georges-tech/georges-lib';
import get from 'lodash/get';
import moment from 'moment';

// Models
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
// Services
import { fetchClosingState, refreshCurrentClosingState } from '@/shared/services/closingStates/closingStates.services';

export const stateData = {
  loading: true,
  closingStates: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser toutes les transactions',
      availableBeforeEndYear: true,
    },
    livreRecettes: {
      title: 'Compléter votre livre des recettes',
      shortTitle: 'Livre des recettes',
      availableBeforeEndYear: true,
    },
    assets: {
      title: 'Gérer vos immobilisations',
      availableBeforeEndYear: true,
    },
    lateChequesPreviousYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId) - 1}`,
      availableBeforeEndYear: true,
    },
    lateChequesThisYear: {
      title: ({ fiscalPeriodId }) => `Chèques non encaissés de ${Number(fiscalPeriodId)}`,
      availableOnlyForClients: true,
    },
    initializeFinancialAccounts: {
      title: 'Vérifier les comptes financiers',
      availableOnlyForClients: true,
    },
    cashRegister: {
      title: 'Évolution de la caisse',
      shortTitle: 'Caisse',
      availableOnlyForClients: true,
    },
    urssaf: {
      title: 'Cotisations Urssaf à ventiler',
      availableOnlyForClients: true,
    },
    fiscalExemptions: {
      title: "Exonérations et crédits d'impôts",
      availableOnlyForClients: true,
    },
    vatCompliance: {
      title: 'Régularisation de la TVA',
      availableOnlyForClients: true,
    },
    bncMicroToReelSwitch: {
      title: 'Optimisation de votre imposition',
      availableOnlyForClients: true,
      previousStepsMustBeDone: true,
    },
    microBncValidateClosing: {
      title: 'Valider la clôture',
      availableOnlyForClients: true,
      isValidatingStep: true,
    },
  },
};

export const getters = {
  loading: ({ loading }) => loading,

  // -- Get a specific CLOSING STATE for a given YEAR
  getClosingState:
    ({ closingStates }) =>
    ({ fiscalPeriodId }) =>
      closingStates[fiscalPeriodId],

  closingState: ({ closingState }) => closingState,
};

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingStates(state, { fiscalPeriodId, closingState }) {
    state.closingStates = {
      ...state.closingStates,
      [fiscalPeriodId]: closingState,
    };
  },
  // ------------------------
  // Reset store ------------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async getClosingStateSteps({ state, getters, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const { defaultSteps } = state;
    const currentFiscalYear = rootGetters['user/currentFiscalYear'];
    const closingState = getters.getClosingState({ fiscalPeriodId: currentFiscalYear });

    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState,
        minDateToClose: moment(activity.date?.minDate),
        fiscalPeriodId: String(currentFiscalYear),
        isInEarlyClosing: get(closingState, 'is_in_early_closing', false),
      },
      user,
      activity,
    });
  },

  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const { closingState } = await refreshCurrentClosingState({
      declaration: DECLARATIONS.D_MICRO_BNC.KEY,
    });
    // commit('setClosingState', { closingState });
    commit('setClosingStates', {
      fiscalPeriodId: closingState.fiscal_year,
      closingState,
    });
    commit('setLoading', false);
  },

  async fetch({ commit }, { fiscalPeriodId }) {
    commit('setLoading', true);
    const { closingState } = await fetchClosingState({
      declaration: DECLARATIONS.D_MICRO_BNC.KEY,
      fiscalPeriodId,
    });
    commit('setClosingState', { closingState });
    commit('setClosingStates', { fiscalPeriodId, closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  mutations,
  actions,
};
