import get from 'lodash/get';
import moment from 'moment';

import { getFiscalDeclarationActivity } from '@/modules/closing/sciFiscalDeclarationActivity/fiscalDeclarationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';
import { isFeatureEnabled } from '@/modules/user/features/features.helpers';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    categorizeAll: {
      title: 'Catégoriser les transactions',
      availableOnlyForClients: false,
      availableBeforeEndYear: true,
    },
    realEstateInformation: {
      title: 'Biens immobiliers', // without bilan
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    realEstateAssets: {
      title: 'Biens immobiliers', // with bilan
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    loanBreakdown: {
      title: "Ventiler les échéances d'emprunts",
      shortTitle: "Échéances d'emprunt",
      availableOnlyForClients: false,
      availableBeforeEndYear: true,
    },
    associatesUpdate: {
      title: `Mise à jour d'associés`,
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    assignAssociatesAccounts: {
      title: 'Comptes courants des associés',
      availableOnlyForClients: true,
      availableBeforeEndYear: true,
    },
    checkFinancialBalances: {
      title: 'Vérifier les soldes bancaires',
      shortTitle: 'Soldes bancaires',
      availableOnlyForClients: true,
    },
    assignPreviousFiscalYearResult: {
      title: 'Affectation du résultat N-1',
      availableOnlyForClients: true,
    },
    sciInformationUpdate: {
      title: 'Mise à jour des informations sur votre SCI',
      availableOnlyForClients: true,
    },
    validateActivity: {
      title: 'Valider et envoyer la déclaration aux impôts',
      shortTitle: 'Valider la déclaration',
      availableOnlyForClients: true,
    },
    validateActivityWithBilan: {
      title: `Valider et envoyer la déclaration aux impôts`,
      shortTitle: 'Valider la déclaration',
      availableOnlyForClients: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const currentUser = rootGetters['user/currentUser'];
    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        closingState: state.closingState,
        minDateToClose: moment(activity.date?.minDate),
        isInEarlyClosing:
          showAdminInfo ||
          isFeatureEnabled({ user: currentUser, feature: 'sciFiscalDeclarationActivityisInEarlyClosing' }),
        fiscalPeriodId: String(userCurrentFiscalYear),
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getFiscalDeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
