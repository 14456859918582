import get from 'lodash/get';
import { defineAsyncComponent, markRaw } from 'vue';

const routes = [];
const isFacturationFeatureEnabled = get(window, 'GEORGES_SETTINGS.featureFlags.facturation', false);
const isFacturationInMaintenanceMode = get(
  window,
  'GEORGES_SETTINGS.featureFlags.freemium.facturation.maintenance',
  false,
);

if (isFacturationFeatureEnabled) {
  routes.push({
    path: '/facturation',
    name: 'facturation',
    component: () => import('@/modules/facturation/Facturation.vue'),
    meta: {
      title: 'Facturation',
      requiresAuth: true,
      ...(!isFacturationInMaintenanceMode && {
        searchBar: markRaw(defineAsyncComponent(() => import('./components/filters/FacturationFilters.vue'))),
        actions: {
          createFacture: markRaw(
            defineAsyncComponent(() => import('@/modules/facturation/components/FacturationItemsButton.vue')),
          ),
        },
      }),
      facturationBeta: true,
    },
    children: [
      {
        name: 'facturation',
        path: '',
        redirect: { name: 'facturation.invoices' },
      },
      {
        path: 'creer-une-facture',
        redirect: { name: 'facturation.invoices.createInvoice' },
      },
      {
        path: 'editer-une-facture/:draftId',
        redirect: { name: 'facturation.invoices.updateDraft' },
      },
      {
        path: 'visualiser-une-facture/:invoiceId',
        redirect: { name: 'facturation.invoices.visualizeInvoice' },
      },
      {
        path: 'importer-une-facture',
        redirect: { name: 'facturation.invoices.createImportedInvoice' },
      },
      {
        path: 'creer-une-facture-d-acompte/:quotationId',
        redirect: { name: 'facturation.invoices.createInvoiceAcompteDraft' },
      },
      {
        path: 'editer-une-facture-d-acompte/:invoiceDraftId',
        redirect: { name: 'facturation.invoices.updateInvoiceAcompteDraft' },
      },
      {
        path: 'editer-une-facture-de-solde/:invoiceDraftId',
        redirect: { name: 'facturation.invoices.updateInvoiceSoldeDraft' },
      },
      {
        path: 'creer-un-devis',
        redirect: { name: 'facturation.quotations.createQuotationDraft' },
      },
      {
        path: 'editer-un-devis/:quotationDraftId',
        redirect: { name: 'facturation.quotations.updateQuotationDraft' },
      },
      {
        path: 'visualiser-un-devis/:quotationId',
        redirect: { name: 'facturation.quotations.visualizeQuotation' },
      },
      {
        name: 'facturation.invoices',
        path: 'factures',
        component: () => import('./components/InvoiceLists.vue'),
        children: [
          {
            name: 'facturation.invoices.createInvoice',
            path: 'creer-une-facture',
            components: {
              panel: () => import('./components/invoices/invoicePanelFullscreen/InvoicePanelFullscreen.vue'),
            },
            meta: {
              title: 'Mes factures',
            },
          },
          {
            name: 'facturation.invoices.updateDraft',
            path: 'editer-une-facture/:draftId',
            components: {
              panel: () => import('./components/invoices/invoicePanelFullscreen/InvoicePanelFullscreen.vue'),
            },
          },
          {
            name: 'facturation.invoices.visualizeInvoice',
            path: 'visualiser-une-facture/:invoiceId',
            components: { panel: () => import('./components/invoices/visualizeInvoice/VisualizeInvoice.vue') },
            meta: {
              title: 'Visualiser ma facture',
            },
          },
          {
            name: 'facturation.invoices.createImportedInvoice',
            path: 'importer-une-facture',
            components: {
              panel: () =>
                import(
                  './components/importedInvoices/createImportedInvoicePanelFullscreen/CreateImportedInvoicePanelFullscreen.vue'
                ),
            },
            meta: {
              title: 'Importer une facture',
            },
          },
          {
            name: 'facturation.invoices.createInvoiceAcompteDraft',
            path: 'creer-une-facture-d-acompte/:quotationId',
            components: {
              panel: () =>
                import(
                  './components/invoices/createInvoiceAcompteDraftPanelFullscreen/CreateInvoiceAcompteDraftPanelFullscreen.vue'
                ),
            },
            meta: {
              title: "Créer une facture d'acompte",
            },
          },
          {
            name: 'facturation.invoices.updateInvoiceAcompteDraft',
            path: 'editer-une-facture-d-acompte/:invoiceDraftId',
            components: {
              panel: () =>
                import(
                  './components/invoices/createInvoiceAcompteDraftPanelFullscreen/CreateInvoiceAcompteDraftPanelFullscreen.vue'
                ),
            },
            meta: {
              title: "Éditer une facture d'acompte",
            },
          },
          {
            name: 'facturation.invoices.updateInvoiceSoldeDraft',
            path: 'editer-une-facture-de-solde/:invoiceDraftId',
            components: {
              panel: () =>
                import(
                  './components/invoices/createInvoiceSoldeDraftPanelFullscreen/CreateInvoiceSoldeDraftPanelFullscreen.vue'
                ),
            },
            meta: {
              title: 'Éditer une facture de solde',
            },
          },
        ],
      },
      {
        name: 'facturation.quotations',
        path: 'devis',
        component: () => import('./components/QuotationLists.vue'),
        children: [
          {
            name: 'facturation.quotations.createQuotationDraft',
            path: 'creer-un-devis',
            components: {
              panel: () =>
                import(
                  './components/quotationDrafts/createQuotationDraftPanelFullscreen/CreateQuotationDraftPanelFullscreen.vue'
                ),
            },
            meta: {
              title: 'Mes devis',
            },
          },
          {
            name: 'facturation.quotations.updateQuotationDraft',
            path: 'editer-un-devis/:quotationDraftId',
            components: {
              panel: () =>
                import(
                  './components/quotationDrafts/createQuotationDraftPanelFullscreen/CreateQuotationDraftPanelFullscreen.vue'
                ),
            },
            meta: {
              title: 'Devis',
            },
          },
          {
            name: 'facturation.quotations.visualizeQuotation',
            path: 'visualiser-un-devis/:quotationId',
            components: { panel: () => import('./components/quotations/visualizeQuotation/VisualizeQuotation.vue') },
            meta: {
              title: 'Visualiser mon devis',
            },
          },
        ],
      },
      {
        name: 'facturation.clients',
        path: 'clients',
        component: () => import('./pages/FacturationClients.vue'),
        meta: {
          title: 'Facturation',
        },
      },
      {
        name: 'facturation.monthlyRetailReport',
        path: 'bilans-mensuels',
        component: () => import('./retail/components/MonthlyRetailReportsList.vue'),
        meta: {
          title: 'Mes bilans mensuels',
        },
      },
    ],
  });
}

export default routes;
