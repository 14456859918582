import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/is-advance-payment${path}`;

export async function getIsAdvancePaymentActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });
  return data;
}

export async function forceCloseActivity({ periodId }) {
  await georgesApi({
    method: 'POST',
    url: getUrl('/force-close'),
    params: {
      periodId,
    },
  });
}

export async function getLastTeletransmissionState({ declaration }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/activities/teletransmissions/last-state',
    params: { declaration },
  });

  return data;
}

export async function checkTeletransmissionPendingExists({ declaration, fiscalPeriodId }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: '/teletransmissions/pending',
    params: { declaration, fiscalPeriodId },
  });
  return data;
}
