import { useStore } from 'vuex';

import { isActivitiesPageVisible } from '@/modules/user/product/product.helpers';

export async function newActivitiesGuard({ next }) {
  const store = useStore();

  const user = store.getters['user/currentUser'];
  const canUserSeeActivities = isActivitiesPageVisible({ user });

  if (!canUserSeeActivities) {
    return next({ name: 'home', replace: true });
  }

  return next();
}
