import georgesApi from '@/shared/apis/georges';
import { logger } from '@/shared/services/logger/logger';

export {
  fetchUser,
  getUserTaxRegime,
  removeDemo,
  sendUserEvent,
  setCompanyName,
  setHasNoDDCToAdd,
  setJob,
  setSiret,
  setUserCompanyCreationDate,
};

async function fetchUser(params) {
  const { data } = await georgesApi({
    url: '/users/me',
    params,
  });
  return data;
}

async function setJob({ jobId, jobName }) {
  const { data } = await georgesApi({
    method: 'PUT',
    url: '/users/me/job',
    data: {
      jobId,
      jobName,
    },
  });

  return data.job;
}

async function setSiret({ siret }) {
  const { data } = await georgesApi({
    method: 'PUT',
    url: `/users/me/siret`,
    data: { siret },
  });
  return data;
}

async function setCompanyName({ companyName }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/users/me/company-name`,
    data: { companyName },
  });
  return data;
}

async function removeDemo() {
  await georgesApi({
    method: 'DELETE',
    url: '/demo',
  });
}

async function setHasNoDDCToAdd() {
  await georgesApi({
    method: 'POST',
    url: 'users/me/ddc',
    data: {
      hasDDCToAdd: false,
    },
  });
}

async function getUserTaxRegime({ year }) {
  const { data } = await georgesApi({
    method: 'GET',
    url: `/users/me/tax-regime`,
    params: {
      year,
    },
  });

  return data;
}

async function sendUserEvent({ eventName, bankId, bankName, connectorType, bankConnectorResponse }) {
  try {
    await georgesApi({
      method: 'POST',
      url: '/users/me/events',
      data: {
        eventName,
        bankId,
        bankName,
        connectorType,
        bankConnectorResponse,
      },
    });
  } catch (err) {
    logger.error({ msg: `sendUserEvent:${eventName}`, args: { err } });
  }
}

async function setUserCompanyCreationDate({ companyCreationDate }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: 'users/me/company-creation-date',
    data: {
      companyCreationDate,
    },
  });

  return data;
}
