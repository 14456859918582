import { FISCAL_REGIMES_DEPRECATED } from '@georges-tech/georges-lib';

import { SETTINGS_CALENDLY } from '@/shared/constants';

import { calendlyByFiscalRegimeKey, calendlyBySchools } from './calendlyButton.constants';

const companyCreationCalendlyLinksKeys = {
  societe: 'companyCreationSociete',
  ei: 'companyCreationEi',
  sci: 'companyCreationSci',
  other: 'companyCreationOther',
  dont_know: 'companyCreationDontKnow',
};

export function getCalendlyId({ user, context }) {
  if (context && calendlyContextToCalendlyIdMap[context]) {
    return calendlyContextToCalendlyIdMap[context]({ user });
  }
  const schoolId = user?.school?.id;
  const onboardingFiscalRegimeKey = user?.signup_funnel?.fiscal_regime_key;

  if (onboardingFiscalRegimeKey === 'BNC_2035' && calendlyBySchools[schoolId]) {
    return calendlyBySchools[schoolId];
  }

  return calendlyByFiscalRegimeKey[onboardingFiscalRegimeKey];
}

export function getCalendlyLink({ calendlyId }) {
  return SETTINGS_CALENDLY.links[calendlyId];
}

export function isUserInBnc({ user }) {
  return user.fiscal_regime ? user.fiscal_regime === FISCAL_REGIMES_DEPRECATED.BNC.KEY : true;
}

// Settings - Delete account, "Allez plus loin avec Indy" banner
export function getUpsellingCalendlyId({ user }) {
  return isUserInBnc({ user }) ? 'indyComptaUpsellingBnc' : 'indyComptaUpsellingIs';
}

// Settings - "Bien démarrer avec Indy" banner
export function getOnboardingCalendlyId() {
  return 'isOnboardingBeforeSignup';
}

// Signup - Crea banner
// Funnel - Siret info
// Support page Premium users
export function getCreaCalendlyId() {
  return 'crea';
}

// Support page Premium users
export function getCreaSciCalendlyId() {
  return 'creaSci';
}

// Funnel - Sci fiscal year not supported
export function getSciFiscalYearNotSupportedCalendlyId() {
  return 'psSci';
}

// Funnel - Company creation Step
export function getCompanyCreationCalendlyId({ user }) {
  const chosenLegalForm = user.signup_funnel.company_creation_chosen_legal_form;
  const unknownCreationId = 'companyCreationDontKnow';

  if (!chosenLegalForm) {
    return unknownCreationId;
  }

  return companyCreationCalendlyLinksKeys[chosenLegalForm] || unknownCreationId;
}

const calendlyContextToCalendlyIdMap = {
  upselling: getUpsellingCalendlyId,
  onboarding: getOnboardingCalendlyId,
  crea: getCreaCalendlyId,
  creaSci: getCreaSciCalendlyId,
  sciFiscalYearNotSupported: getSciFiscalYearNotSupportedCalendlyId,
  companyCreation: getCompanyCreationCalendlyId,
};

// Relatif aux UTMs - Permet de répliquer coté App ce qui est fait coté WWW pour aider le marketing
export function formatUtmContent({ user }) {
  const utms = user?.profile?.utms;

  return `${getUtmOrNullString({ utms, utmKey: 'firstlandingpage' })}|${getUtmOrNullString({
    utms,
    utmKey: 'firstreferer',
  })}|${user.signup_funnel?.promocode ?? 'null'}|${getUtmOrNullString({ utms, utmKey: 'subId' })}`;
}

function getUtmOrNullString({ utms, utmKey }) {
  return utms?.[utmKey] ? utms?.[utmKey] : 'null';
}
