import { joinURL } from 'ufo';

import { logger } from '../services/logger/logger';

export async function useChunkError({ store, router }) {
  const chunkErrors = new Set();

  /*
   * Clear errors before loading new route
   */
  router.beforeEach(() => {
    chunkErrors.clear();
  });

  /*
   * Listen vite preloadError event to catch errors
   * @see https://github.com/vitejs/vite/blob/main/packages/vite/src/node/plugins/importAnalysisBuild.ts#L149
   */
  window.addEventListener('vite:preloadError', (err) => {
    if (err.payload) chunkErrors.add(err.payload);
  });

  /*
   * On error, check if vite returned the same error
   * if true: log error and reload app
   * else do nothing
   */
  router.onError((error, to) => {
    if (chunkErrors.has(error)) {
      logChunkError({ error, to, user: store?.state?.user?.currentUser });
      reloadAppAtPath(to);
    }
  });
}

function reloadAppAtPath(to) {
  const baseUrl = window.location.origin;
  const isHash = 'href' in to && to.href[0] === '#';
  const path = isHash ? baseUrl + to.href : joinURL(baseUrl, to.fullPath);
  reloadApp({ path });
}

/* Number of milliseconds in which to ignore future reload requests */
const ttl = 10000;

function reloadApp(options) {
  const path = options.path || window.location.pathname;

  let handledPath = {};
  try {
    handledPath = JSON.parse(sessionStorage.getItem('indy:reload') || '{}');
  } catch {
    // fail gracefully if we can't access localStorage
  }

  if (handledPath?.path !== path || handledPath?.expires < Date.now()) {
    try {
      sessionStorage.setItem('indy:reload', JSON.stringify({ path, expires: Date.now() + ttl }));
    } catch {
      // fail gracefully if we can't access localStorage
    }

    if (window.location.pathname !== path) {
      window.location.href = path;
    } else {
      window.location.reload();
    }
  }
}

function logChunkError({ error, to, user } = {}) {
  logger.log({
    ...(user && { userId: user._id }),
    msg: 'Reloading page to fix dynamic import error',
    args: {
      to,
      error,
    },
  });
}
