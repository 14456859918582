import { getClients } from '../facturation.services';

const stateData = {
  clients: [],
  isLoading: false,
  isError: false,
  filters: {
    currentPage: 1,
  },
};

export const mutations = {
  setClients: (state, clients) => {
    state.clients = clients;
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading;
  },
  setIsError: (state, isError) => {
    state.isError = isError;
  },
  setCurrent: (state, currentPage) => {
    state.filters.currentPage = currentPage;
  },
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const actions = {
  async fetchClients({ commit, state }) {
    commit('setIsLoading', true);

    try {
      const clients = await getClients({
        currentPage: state.filters.currentPage,
      });

      commit('setClients', clients);
    } catch (e) {
      commit('setIsError', true);
    } finally {
      commit('setIsLoading', false);
    }
  },
  updateCurrentPage({ commit, dispatch }, currentPage) {
    commit('setCurrentPage', currentPage);
    dispatch('fetchClients');
  },
};

export const getters = {
  clients: ({ clients }) => clients,
  isLoading: ({ isLoading }) => isLoading,
  currentPage: ({ currentPage }) => currentPage,
};

export default {
  namespaced: true,
  state: { ...stateData },
  mutations,
  actions,
  getters,
};
