import get from 'lodash/get';
import last from 'lodash/last';

import { USER_SCOPE } from '@/modules/comptePro/comptePro.constants';

const couponsToMonthsFree = {
  'two-months-free': 2,
  '2-months-free': 2,
  '3-months-free': 3,
};

export { getImpersonationFailureReasonKey, getPromocodeAdvantageText, getUserCouponMessage, isInScopeComptePro };

function getUserCouponMessage({ userCoupons, labelProperty }) {
  // Prendre le dernier plutot que le premier
  const coupon = last(userCoupons);

  const promocodeAdvantageText = getPromocodeAdvantageText({ coupon });

  return get(coupon, `labels.${labelProperty}`, promocodeAdvantageText);
}

function getPromocodeAdvantageText({ coupon }) {
  const stripeCustomerCoupon = get(coupon, 'advantages.stripe_customer_coupon');
  const freeMonthsCount = get(couponsToMonthsFree, stripeCustomerCoupon, 1);
  const offeredText = freeMonthsCount > 1 ? 'offerts' : 'offert';

  return `${freeMonthsCount} mois ${offeredText} à l’abonnement.`;
}

function getImpersonationFailureReasonKey({ responseCode }) {
  if (responseCode === 'impersonate.user_is_admin') {
    return 'imitating_admin';
  }

  if (responseCode === 'impersonate.user_is_soft_deleted') {
    return 'imitating_deleted_user';
  }

  return 'imitation_failed';
}

function isInScopeComptePro({ compteProUserScope }) {
  return compteProUserScope === USER_SCOPE.IN_SCOPE;
}
