import get from 'lodash/get';
import { mapGetters } from 'vuex';

import ChurnedSciScmModal from '@/modules/core/subscriptionModals/churnedSciScm/ChurnedSciScmModal.vue';
import LastChargeFailModal from '@/modules/core/subscriptionModals/lastChargeFailModal/LastChargeFailModal.vue';
import SubscriptionIncompleteModal from '@/modules/core/subscriptionModals/subscriptionIncompleteModal/SubscriptionIncompleteModal.vue';
import {
  getSubscriptionProblemModalToShow,
  shouldBypassSubscriptionProblemModal,
} from '@/modules/core/subscriptionModals/subscriptionModals.model';
// Mixins
import Dialogable from '@/shared/mixins/dialogable';
import Trackable from '@/shared/mixins/trackable';
import { isActiveRoute } from '@/shared/utils/routing';

export default {
  name: 'SubscriptionModalsMixin',
  mixins: [Dialogable, Trackable],
  data: () => ({
    instance: undefined,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
      hasAllRequiredPermissions: 'auth/hasAllRequiredPermissions',
      showAdminInfo: 'user/showAdminInfo',
      isBookkeeping: 'user/isBookkeeping',
      isImpersonating: 'user/isImpersonating',
      isLastChargeFailed: 'user/isLastChargeFailed',
      hasChurnedInSciOrScm: 'user/hasChurnedInSciOrScm',
      isUserSubscriptionIncomplete: 'settings/isUserSubscriptionIncomplete',
      canSeeComptePro: 'comptePro/canSeeComptePro',
      hasStartedComptePro: 'comptePro/hasStartedComptePro',
    }),
    userIsAdmin() {
      return this.hasAllRequiredPermissions(['admin']);
    },
  },
  watch: {
    isLastChargeFailed: {
      handler: 'handleSubscriptionProblemModals',
      immediate: true,
    },
    isUserSubscriptionIncomplete: {
      handler: 'handleSubscriptionProblemModals',
      immediate: true,
    },
    $route: {
      handler: 'handleSubscriptionProblemModals',
      deep: true,
    },
  },
  beforeUnmount() {
    this.destroyModals();
  },
  created() {
    if (get(this, 'currentUser.stripe.customer_id')) {
      this.$store.dispatch('settings/getStripeCustomerData');
    }
  },
  methods: {
    handleSubscriptionProblemModals() {
      if (
        shouldBypassSubscriptionProblemModal({
          isLastChargeFailed: this.isLastChargeFailed,
          isUserSubscriptionIncomplete: this.isUserSubscriptionIncomplete,
          hasChurnedInSciOrScm: this.hasChurnedInSciOrScm,
          userIsAdmin: this.userIsAdmin,
          isImpersonating: this.isImpersonating,
          showAdminInfo: this.showAdminInfo,
          routeName: this.$route.name,
          canSeeComptePro: this.canSeeComptePro,
        })
      ) {
        this.destroyModals();
        return;
      }

      if (!this.isImpersonating && !this.isActiveRoute('/') && !this.isBookkeeping) {
        this.$router.push('/');
      }

      const modalName = getSubscriptionProblemModalToShow({
        hasStartedComptePro: this.hasStartedComptePro,
        isLastChargeFailed: this.isLastChargeFailed,
        isBookkeeping: this.isBookkeeping,
        isUserSubscriptionIncomplete: this.isUserSubscriptionIncomplete,
        hasChurnedInSciOrScm: this.hasChurnedInSciOrScm,
      });

      if (modalName) {
        this.showSubscriptionProblemModal({
          modalName,
        });
      }
    },
    showSubscriptionProblemModal({ modalName }) {
      if (this.instance) return;

      const modalComponents = {
        lastChargeFailModal: LastChargeFailModal,
        lastChargeIncompleteModal: SubscriptionIncompleteModal,
        churnedSciScmModal: ChurnedSciScmModal,
      };

      this.trackEvent({
        action: 'open_subscription_problem_modal',
        properties: {
          modalName,
        },
      });

      this.instance = this.openDialog(
        {
          component: modalComponents[modalName],
          componentProps: {
            name: modalName,
            clickToClose: Boolean(this.isImpersonating),
          },
          dialogProps: {
            maxWidth: 660,
            bottomsheet: this.$georgesuikit.breakpoint.isMobile,
            persistent: !this.isImpersonating,
          },
          events: {
            closed: () => {
              this.destroyModals();
            },
          },
        },
        'action',
      );
    },
    destroyModals() {
      if (this.instance) {
        this.$gdialog.hide(this.instance);
        this.instance = undefined;
      }
    },
    isActiveRoute,
  },
};
