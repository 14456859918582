<template>
  <template v-if="displayType === 'popup'">
    <GButton
      v-bind="$attrs"
      v-if="variant !== 'link'"
      :variant="variant"
      :size="xLarge ? 'x-large' : undefined"
      :append-icon="appendIcon"
      @click="openCalendlyPopup"
    >
      <slot />
    </GButton>
    <GButtonLink
      v-else
      v-bind="$attrs"
      :size="xLarge ? 'x-large' : undefined"
      :append-icon="appendIcon"
      @click="openCalendlyPopup"
    >
      <slot />
    </GButtonLink>
  </template>
  <template v-else-if="displayType === 'inline'">
    <slot>
      <div ref="calendlyInlineWidget" />
    </slot>
  </template>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { logger } from '@/shared/services/logger/logger';

import { formatUtmContent, getCalendlyId, getCalendlyLink } from './calendlyButton.model';

export default {
  name: 'CalendlyButton',
  emits: ['eventScheduled', 'click'],

  props: {
    xLarge: { type: Boolean, default: false },
    calendlyId: { type: String, default: undefined },
    variant: { type: String, default: 'link' },
    appendIcon: { type: String, default: undefined },
    context: { type: String, default: undefined },
    displayType: {
      type: String,
      default: 'popup',
      validator: (value) => ['popup', 'inline'].includes(value),
    },
  },

  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
    calendlyLink() {
      const calendlyId = this.calendlyId || getCalendlyId({ user: this.currentUser, context: this.context });
      return getCalendlyLink({
        calendlyId,
      });
    },
    getCalendlyUtm() {
      return {
        utmCampaign: this.currentUser.profile?.utms?.campaign,
        utmSource: this.currentUser.profile?.utms?.source,
        utmMedium: this.currentUser.profile?.utms?.medium,
        utmContent: formatUtmContent({ user: this.currentUser }),
        // détournement de l'utilisation de l'utmTerm pour injecter un gclid
        // car Calendly ne nous propose pas d'autres champs pour le tracking
        // que les 5 types d'UTMs
        // cf slack: https://georgestech.slack.com/archives/CKXD77E06/p1726213036648199?thread_ts=1726147615.572649
        utmTerm: this.currentUser.profile?.utms?.gclid,
      };
    },
  },

  mounted() {
    if (this.displayType === 'inline') {
      this.loadCalendly();
    }
    // L'event ne peut pas être déclenché dans la plupart des calendlies car ils redirigent vers un autre onglet
    window.addEventListener('message', this.onCalendlyEventScheduled);
  },

  unmounted() {
    window.removeEventListener('message', this.onCalendlyEventScheduled);
  },

  methods: {
    showCalendlyError({ wrongCalendlyLink, calendlyNotLoaded }) {
      this.$gnotify.error(
        'Oups, notre service de prise de rendez-vous semble indisponible, vous pouvez recharger la page, réessayer plus tard ou contacter le service client.',
      );
      logger.error({
        msg: '[CalendlyButton] calendly error',
        args: {
          wrongCalendlyLink,
          calendlyNotLoaded,
        },
      });
      return;
    },
    onCalendlyEventScheduled(e) {
      if (get(e, 'data.event') === 'calendly.event_scheduled') {
        this.$emit('eventScheduled');
      }
    },
    loadCalendly() {
      if (!this.calendlyLink || !window.Calendly) {
        this.showCalendlyError({ wrongCalendlyLink: !this.calendlyLink, calendlyNotLoaded: !window.Calendly });
        return;
      }
      const calendlyElement = this.$refs.calendlyInlineWidget || this.$slots.default?.()[0]?.el;

      window.Calendly.initInlineWidget({
        parentElement: calendlyElement,
        url: `${this.calendlyLink}`,
        resize: true,
        utm: this.getCalendlyUtm,
      });
    },
    openCalendlyPopup() {
      this.$emit('click');
      if (!window.Calendly || !this.calendlyLink) {
        this.showCalendlyError({ wrongCalendlyLink: !this.calendlyLink, calendlyNotLoaded: !window.Calendly });
        return;
      }
      window.Calendly.initPopupWidget({
        url: this.calendlyLink,
        utm: this.getCalendlyUtm,
      });
    },
  },
};
</script>
