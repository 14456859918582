import get from 'lodash/get';

import { canUseComptePro } from '@/modules/comptePro/comptePro.models';
import {
  BOOKKEEPING_PRODUCT_KEY,
  COMMITMENT_ACCOUNTING_MODE,
  DECLARATION_PRODUCT_KEY,
  FACTURATION_FEATURE_NAME,
  RETAIL_FEATURE_NAME,
} from '@/modules/user/product/product.constants';

import { isFeatureEnabled } from '../features/features.helpers';

export function isActivitiesPageVisible({ user }) {
  return user?.isSupported;
}

export function isUserOnBookkeepingProduct({ user }) {
  return get(user, 'product', []).includes(BOOKKEEPING_PRODUCT_KEY);
}

export function isUserOnDeclarationProduct({ user }) {
  return get(user, 'product', []).includes(DECLARATION_PRODUCT_KEY);
}

export function userCanSeeInvoiceMenu({ user }) {
  return userHasAccessToInvoice({ user });
}

export function getTransactionsTabName({ user }) {
  if (userCanSeeComptePro({ user })) {
    return 'Compte pro';
  } else {
    return 'Transactions';
  }
}

export function getTransactionsTabIcon({ user }) {
  if (userCanSeeComptePro({ user })) {
    return 'fa-university';
  } else {
    return 'fa-exchange';
  }
}

export function userCanSeeComptePro({ user }) {
  const userStatus = get(user, 'comptePro.userStatus.status');

  if (canUseComptePro({ userStatus })) {
    return true;
  }

  if (get(user, 'comptePro.hasStarted', false)) {
    return true;
  }

  return false;
}

export function userHasAccessToInvoice({ user }) {
  if (!get(window, 'GEORGES_SETTINGS.featureFlags.facturation', false)) {
    return false;
  }

  return isFeatureEnabled({ user, feature: FACTURATION_FEATURE_NAME });
}

export function userHasAccessToRetailFeature({ user, currentFiscalYearConfiguration }) {
  const hasFacturationEnabled = isFeatureEnabled({ user, feature: FACTURATION_FEATURE_NAME });
  const salesAccountingMode = get(currentFiscalYearConfiguration, 'salesAccountingMode');

  return hasFacturationEnabled && salesAccountingMode === COMMITMENT_ACCOUNTING_MODE;
}

export function userHasEnabledRetailFeature({ user }) {
  return isFeatureEnabled({ user, feature: RETAIL_FEATURE_NAME });
}

export function shouldShowCapitalDepositButton({
  hasCapitalDepositEnabled,
  hasBeenRedirectedToLegalPlace,
  hasFilledLegalPlaceForm,
  hasReceivedKbis,
  hasCompletedCapitalDeposit,
}) {
  return (
    hasCapitalDepositEnabled &&
    hasBeenRedirectedToLegalPlace &&
    hasFilledLegalPlaceForm &&
    !hasReceivedKbis &&
    !hasCompletedCapitalDeposit
  );
}
