export const ACTIVITIES = {
  bicIsFiscalDeclarationActivity: {
    ALWAYS_AVAILABLE: true,
    configurable: true,
    description: 'Gérez vos immobilisations, frais de véhicule, créances clients, etc.',
  },
  bicIsInitialisationDuBilanActivity: {
    configurable: true,
  },
  caDeclarationActivity: {
    description: `Télédéclarez votre chiffre d'affaires à l'Urssaf`,
  },
  d2035Activity: {
    ALWAYS_AVAILABLE: true,
    description: 'Gérez vos immobilisations, frais de véhicule, créances clients, etc.',
  },
  vatActivity: {
    configurable: true,
  },
  dCfeActivity: {
    description: "Déclarez votre local professionnel et votre chiffre d'affaires estimé à votre service des impôts.",
  },
  microBicFiscalDeclarationActivity: {
    ALWAYS_AVAILABLE: true,
    description: 'Catégorisez vos transactions professionnelles.',
  },
  microBncActivity: {
    ALWAYS_AVAILABLE: true,
    description:
      'Catégorisez vos transactions professionnelles. Indy vous indiquera si vous devez passer aux frais réels',
  },
  sciFiscalDeclarationActivity: {
    ALWAYS_AVAILABLE: true,
    description: 'Catégorisez vos transactions professionnelles.',
  },
  sciIsFiscalDeclarationActivity: {
    ALWAYS_AVAILABLE: true,
    description: 'Catégorisez vos transactions professionnelles.',
  },
  d2036Activity: {
    ALWAYS_AVAILABLE: true,
    description: 'Gérez vos immobilisations, frais de véhicule, créances clients, etc.',
  },
};

export const ACTIVITY_HELP = {
  vatActivity: {
    vatCreditReportable: {
      layout: [
        {
          tag: 'GText',
          text: 'Où trouver le montant du crédit reportable ?',
          props: {
            variant: 'bodyL',
            weight: 'bold',
          },
        },
        {
          type: 'paragraph',
          children: [
            {
              tag: 'span',
              text: 'Munissez-vous à votre dernière déclaration de TVA. Le montant à reporter est sur la ',
            },
            {
              tag: 'GChip',
              text: 'ligne 27',
              props: {
                color: 'info',
                size: 'small',
              },
            },
          ],
        },
        {
          tag: 'GText',
          text: 'Comment retrouver ma déclaration de TVA ?',
          props: {
            variant: 'bodyL',
            weight: 'bold',
          },
        },
        {
          type: 'paragraph',
          children: [
            {
              tag: 'span',
              text: 'Rendez-vous sur ',
            },
            {
              tag: 'GButtonLink',
              text: 'impôts.gouv',
              props: {
                href: 'https://cfspro-idp.impots.gouv.fr/oauth2/authorize?response_type=code&redirect_uri=https://cfspro.impots.gouv.fr/?openidconnectcallback%3D1&nonce=1657097555_17563&client_id=id_cfspro_prod&display=&state=1657097555_26298&scope=openid%20profile%20email%20info',
              },
            },
            {
              tag: 'span',
              text: ' et suivez notre tutoriel\u00a0:',
            },
          ],
        },
        {
          type: 'video',
          link: 'https://www.youtube.com/embed/UvrYgx-MqPg?si=CO03osEEI3C9cQqd',
        },
        {
          tag: 'GBanner',
          props: {
            variant: 'tonal',
            color: 'info',
            image: '/img/illustrations/LightGlobe.svg',
            text: "Si vous avez payé plus de TVA sur vos achats que ce que vous avez collecté sur vos ventes, l'État vous doit de l'argent ! C'est ce qu'on appelle un crédit de TVA.",
            title: "Le crédit de TVA, c'est quoi ?",
          },
        },
      ],
    },
    categorizeAll: {
      layouts: {
        userIsBnc: [
          {
            tag: 'GText',
            text: 'Pourquoi catégoriser ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Catégoriser une transaction permet de ',
              },
              {
                tag: 'span',
                text: ' la lier au bon compte comptable. ',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Avec Indy, la plupart de vos transactions sont catégorisées automatiquement.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: "Certaines, plus délicates, ou dont l'intitulé n'est pas assez précis, peuvent échapper au premier niveau de catégorisation.",
              },
            ],
          },
          {
            tag: 'GBanner',
            props: {
              variant: 'tonal',
              color: 'info',
              image: '/img/illustrations/LightGlobe.svg',
              text: 'Pensez à vérifier aussi les catégories attribuées par Indy afin de garantir la justesse de votre comptabilité.',
            },
          },
          {
            tag: 'GText',
            text: "Comment trouver la bonne catégorie d'une transaction bancaire ?",
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'On vous explique tout ',
              },
              {
                tag: 'GButtonLink',
                text: 'dans cet article.',
                props: {
                  href: 'https://wikicompta.indy.fr/fr/articles/451534-comment-categoriser-une-transaction',
                  target: 'blank',
                },
              },
            ],
          },
        ],
        userIsIs: [
          {
            tag: 'GText',
            text: 'Pourquoi catégoriser ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Catégoriser une transaction permet de ',
              },
              {
                tag: 'span',
                text: ' la lier au bon compte comptable. ',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Avec Indy, la plupart de vos transactions sont catégorisées automatiquement.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: "Certaines, plus délicates, ou dont l'intitulé n'est pas assez précis, peuvent échapper au premier niveau de catégorisation.",
              },
            ],
          },
          {
            tag: 'GBanner',
            props: {
              variant: 'tonal',
              color: 'info',
              image: '/img/illustrations/LightGlobe.svg',
              text: 'Pensez à vérifier aussi les catégories attribuées par Indy afin de garantir la justesse de votre comptabilité.',
            },
          },
          {
            tag: 'GText',
            text: "Comment trouver la bonne catégorie d'une transaction bancaire ?",
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'On vous explique tout ',
              },
              {
                tag: 'GButtonLink',
                text: 'dans cet article.',
                props: {
                  href: 'https://wikicompta.indy.fr/fr/articles/4344903-dans-quelle-rubrique-categoriser-une-transaction',
                  target: 'blank',
                },
              },
            ],
          },
        ],
      },
    },
    vatRateVerification: {
      layouts: {
        userIsBnc: [
          {
            tag: 'GText',
            text: 'Comment vérifier les taux de TVA ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Allez dans ',
              },
              {
                tag: 'GButtonLink',
                text: "l'onglet transactions",
                props: {
                  href: '/transactions',
                  target: 'blank',
                },
              },
              {
                tag: 'span',
                text: ' et vérifiez que pour vos achats et ventes, les ',
              },
              {
                tag: 'span',
                text: 'taux de TVA appliqués',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ' correspondent bien aux ',
              },
              {
                tag: 'span',
                text: 'taux indiqués sur vos factures.',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'C’est pourquoi il est important de conserver les factures. Elles vous servent de justificatifs de paiements, mais également pour vérifier la TVA appliquée dessus.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'GText',
                text: 'Quelques spécificités à connaître',
                props: {
                  class: 'm-b-16',
                  variant: 'bodyL',
                  weight: 'bold',
                },
              },
              {
                tag: 'GButtonLink',
                text: 'La TVA sur les frais bancaires',
                props: {
                  class: 'm-b-12',
                  href: 'https://wikicompta.indy.fr/fr/articles/4636261-quel-taux-de-tva-sur-mes-frais-bancaires',
                  target: 'blank',
                },
              },
              {
                tag: 'GButtonLink',
                text: "La TVA sur les services à l'étranger",
                props: {
                  class: 'm-b-12',
                  href: 'https://wikicompta.indy.fr/fr/articles/4322057-quel-taux-de-tva-appliquer-sur-vos-depenses-a-l-etranger',
                  target: 'blank',
                },
              },
              {
                tag: 'GButtonLink',
                text: 'La TVA pour les frais de carburant',
                props: {
                  class: 'm-b-12',
                  href: 'https://wikicompta.indy.fr/fr/articles/3733101-comment-deduire-la-tva-sur-les-frais-de-carburant',
                  target: 'blank',
                },
              },
            ],
          },
        ],
        userIsIs: [
          {
            tag: 'GText',
            text: 'Comment vérifier les taux de TVA ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Allez dans ',
              },
              {
                tag: 'GButtonLink',
                text: "l'onglet transactions",
                props: {
                  href: '/transactions',
                  target: 'blank',
                },
              },
              {
                tag: 'span',
                text: ' et vérifiez que pour vos achats et ventes, les ',
              },
              {
                tag: 'span',
                text: 'taux de TVA appliqués',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ' correspondent bien aux ',
              },
              {
                tag: 'span',
                text: 'taux indiqués sur vos factures.',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'C’est pourquoi il est important de conserver les factures. Elles vous servent de justificatifs de paiements, mais également pour vérifier la TVA appliquée dessus.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'GText',
                text: 'Quelques spécificités à connaître',
                props: {
                  class: 'm-b-16',
                  variant: 'bodyL',
                  weight: 'bold',
                },
              },
              {
                tag: 'GButtonLink',
                text: 'La TVA sur les frais bancaires',
                props: {
                  class: 'm-b-12',
                  href: 'https://wikicompta.indy.fr/fr/articles/4636261-quel-taux-de-tva-sur-mes-frais-bancaires',
                  target: 'blank',
                },
              },
              {
                tag: 'GButtonLink',
                text: "La TVA sur les services à l'étranger",
                props: {
                  class: 'm-b-12',
                  href: 'https://wikicompta.indy.fr/fr/articles/4322057-quel-taux-de-tva-appliquer-sur-vos-depenses-a-l-etranger',
                  target: 'blank',
                },
              },
            ],
          },
        ],
      },
    },
    vatCreditRefundDemand: {
      layouts: {
        vatRefundRequestable: [
          {
            tag: 'GText',
            text: 'Comment demander le remboursement ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            class: 'd-flex flex-column',
            children: [
              {
                tag: 'span',
                text: 'La demande de remboursement s’effectue en plus de la déclaration de TVA  via le ',
              },
              {
                tag: 'span',
                text: 'formulaire 3519-SD.',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Indy ne prépare pas cette déclaration, c’est à vous d’effectuer la demande depuis le site des impôts.',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            tag: 'GText',
            text: 'Comment remplir ma demande sur le site des impôts ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Rendez-vous sur ',
              },
              {
                tag: 'GButtonLink',
                text: ' impôts.gouv ',
                props: {
                  href: 'https://cfspro-idp.impots.gouv.fr/oauth2/authorize?response_type=code&redirect_uri=https://cfspro.impots.gouv.fr/?openidconnectcallback%3D1&nonce=1657097555_17563&client_id=id_cfspro_prod&display=&state=1657097555_26298&scope=openid%20profile%20email%20info',
                },
              },
              {
                tag: 'span',
                text: ' et suivez notre tutoriel\u00a0:',
              },
            ],
          },
          {
            type: 'video',
            link: 'https://www.youtube.com/embed/FZsY6QCZ2dA?si=-0MXT0LW-BJCOa5Z',
          },
        ],
      },
    },
    companyIdentity: {
      layouts: {
        canOptInForTeletransmission: [
          {
            tag: 'GText',
            text: 'Qu’est ce que la télétransmission ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'La télétransmission',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ' permet d’envoyer directement votre déclaration de TVA aux impôts ',
              },
              {
                tag: 'span',
                text: 'depuis Indy.',
                props: {
                  class: 'font-weight-bold',
                },
              },
            ],
          },
          {
            type: 'image',
            src: '/img/illustrations/teletransmission.png',
            props: {
              height: '115px',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Vous serez prélevé automatiquement du montant à payer en cas de TVA due (autour du 24 du mois suivant).',
              },
            ],
          },
          {
            tag: 'GText',
            text: 'Qu’est ce que la télédéclaration ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: "Si vous n'optez pas pour la télétransmission, vous allez devoir télédéclarer votre déclaration directement sur le site des impôts. Pour cela, vous devez reporter les données de déclaration produites par Indy sur votre espace professionnel des impôts\u00a0: il s'agit ici d'une action à effectuer de votre côté.",
              },
            ],
          },
        ],
        hasVatCreditRefundDemand: [
          {
            tag: 'GText',
            text: 'Pourquoi la télétransmission n’est pas possible ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'La ',
              },
              {
                tag: 'span',
                text: 'télétransmission',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', qui permet d’envoyer directement votre déclaration de TVA aux impôts ',
              },
              {
                tag: 'span',
                text: 'depuis Indy',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', n’est pas disponible dans le cas d’une demande de remboursement de TVA car vous devez ajouter le formulaire de demande de remboursement (3519).',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Vous allez donc devoir ',
              },
              {
                tag: 'span',
                text: 'télédéclarer',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ". Vous devez alors reporter les données de déclaration produites par Indy sur votre espace professionnel des impôts\u00a0: il s'agit ici d'une action à effectuer de votre côté.",
              },
            ],
          },
        ],
        hasVehicleTaxes: [
          {
            tag: 'GText',
            text: 'Pourquoi la télétransmission n’est pas possible ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'La ',
              },
              {
                tag: 'span',
                text: 'télétransmission',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', qui permet d’envoyer directement votre déclaration de TVA aux impôts ',
              },
              {
                tag: 'span',
                text: 'depuis Indy',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', n’est pas disponible dans le cas où vous êtes éligible aux taxes sur les véhicules.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Vous allez donc devoir ',
              },
              {
                tag: 'span',
                text: 'télédéclarer',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ". Vous devez alors reporter les données de déclaration produites par Indy sur votre espace professionnel des impôts\u00a0: il s'agit ici d'une action à effectuer de votre côté.",
              },
            ],
          },
        ],
        hasTvaIntracom: [
          {
            tag: 'GText',
            text: 'Pourquoi la télétransmission n’est pas possible ?',
            props: {
              variant: 'bodyL',
              weight: 'bold',
            },
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'La ',
              },
              {
                tag: 'span',
                text: 'télétransmission',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', qui permet d’envoyer directement votre déclaration de TVA aux impôts ',
              },
              {
                tag: 'span',
                text: 'depuis Indy',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ', n’est pas disponible dans le cas d’opérations intracommunautaires.',
              },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                tag: 'span',
                text: 'Vous allez donc devoir ',
              },
              {
                tag: 'span',
                text: 'télédéclarer',
                props: {
                  class: 'font-weight-bold',
                },
              },
              {
                tag: 'span',
                text: ". Vous devez alors reporter les données de déclaration produites par Indy sur votre espace professionnel des impôts\u00a0: il s'agit ici d'une action à effectuer de votre côté.",
              },
            ],
          },
        ],
      },
    },
    // vatValidateClosing: {
    //   layouts: {
    //     teletransmissionDisabled: [
    //       {
    //         tag: 'GText',
    //         text: 'Comment la TVA est-elle calculée ?',
    //         props: {
    //           variant: 'bodyL',
    //           weight: 'bold',
    //         },
    //       },
    //       {
    //         type: 'paragraph',
    //         children: [
    //           {
    //             tag: 'span',
    //             text: 'La TVA est calculée en ',
    //           },
    //           {
    //             tag: 'span',
    //             text: 'soustrayant la lignes 16 à la ligne 23.',
    //             props: {
    //               class: 'font-weight-bold',
    //             },
    //           },
    //         ],
    //       },
    //       {
    //         type: 'paragraph',
    //         children: [
    //           {
    //             tag: 'span',
    //             text: 'Si la TVA collectée (ligne 16) est supérieure à la TVA déductible (ligne 23) alors la TVA ',
    //           },
    //           {
    //             tag: 'span',
    //             text: 'est due',
    //             props: {
    //               class: 'font-weight-bold',
    //             },
    //           },
    //           {
    //             tag: 'span',
    //             text: '\u00a0: c’est de l’argent que vous devez payer à l’Etat.',
    //           },
    //         ],
    //       },
    //       {
    //         type: 'paragraph',
    //         children: [
    //           {
    //             tag: 'span',
    //             text: 'Si la TVA collectée collectée est inférieure à la TVA déductible alors c’est un ',
    //           },
    //           {
    //             tag: 'span',
    //             text: 'crédit',
    //             props: {
    //               class: 'font-weight-bold',
    //             },
    //           },
    //           {
    //             tag: 'span',
    //             text: ' de TVA\u00a0: c’est de l’argent que l’Etat vous doit.',
    //           },
    //         ],
    //       },
    //       {
    //         type: 'image',
    //         src: '/img/illustrations/vat-calculation.png',
    //       },
    //       {
    //         tag: 'GText',
    //         text: 'Que faire en cas de télédéclaration ?',
    //         props: {
    //           variant: 'bodyL',
    //           weight: 'bold',
    //         },
    //       },
    //       {
    //         type: 'paragraph',
    //         children: [
    //           {
    //             tag: 'span',
    //             text: 'Si vous avez choisi de télédéclarer, vous devez maintenant reporter les montants de votre Déclaration produite par Indy sur votre espace professionnel ',
    //           },
    //           {
    //             tag: 'GButtonLink',
    //             text: ' impôts.gouv ',
    //             props: {
    //               href: 'https://cfspro-idp.impots.gouv.fr/oauth2/authorize?response_type=code&redirect_uri=https://cfspro.impots.gouv.fr/?openidconnectcallback%3D1&nonce=1657097555_17563&client_id=id_cfspro_prod&display=&state=1657097555_26298&scope=openid%20profile%20email%20info',
    //             },
    //           },
    //           {
    //             tag: 'span',
    //             text: '.',
    //           },
    //         ],
    //       },
    //       {
    //         type: 'paragraph',
    //         children: [
    //           {
    //             tag: 'span',
    //             text: 'En cas de doute, n’hésitez pas à contacter le support.',
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // },
  },
};
